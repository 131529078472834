<template>
	<div class="main">
		<TheTopbar/>

    <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
    <slot />
	<div class="main-container">
    <div class="d-flex">
      <div class="content-wrapper">
        <Header></Header>
          <div class="options">
                  
                  <button class="btn btn-main " @click="goToProfile()" :disabled="!init.FXCUSTVIEW" v-b-tooltip.hover title="Click to view and edit the profile information of the authorized representative">AUTHORIZED REP</button>
                  <button class="btn btn-main active" @click="goToCompany()" :disabled="!init.FXCUSTVIEW" v-b-tooltip.hover title="Click to view and edit the profile information of the company">COMPANY</button>
                   <button class="btn btn-main" @click="goToApplication()" :disabled="!init.FXCUSTAPPLICATION" v-b-tooltip.hover title="Click to view and action the application process of the customer">APPLICATION</button>
                  <!-- <button class="btn btn-main" @click="goToUsers()" :disabled="!init.FXCUSTUSERS">USERS</button> -->
                  <button class="btn btn-main" @click="goToTransactions()" :disabled="!init.FXCUSTTRANSACTIONS" v-b-tooltip.hover title="Click to view and monitor the transactions made by the customer">TRANSACTIONS</button>
                  <button class="btn btn-main" @click="goToActivityLogs()" v-b-tooltip.hover title="Click to view and monitor the activity logs of the customer">ACTIVITY LOGS</button>
            </div>
         
          <div v-if="errors.profile.show">
            <p style="color: lightgray;">{{errors.profile.msg}}</p>
          </div>
          
          <div class="market-content" >
             <form >
              <!-- <button class="btn btn-secondary" @click.prevent="editBusinessInfo()" v-if="!isEditing" :disabled="!init.FXCUSTEDIT">Edit
                </button>
                <div class="text-center" v-if="isEditing"> 
                    <span >
                      <button class="btn btn-secondary" type="submit" @click.prevent="updateExtCorpProfile()" :disabled="!init.FXCUSTEDIT">Save</button>
                    </span>
                    <span ><button class="btn btn-secondary" v-on:click="cancelEditExt" :disabled="!init.FXCUSTEDIT">Cancel</button></span>
                    
                  </div> -->
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Business Type:
                      </th>
                      <th>
                       Registered Business Name:
                      </th>
                     
                    </tr>
                  </thead>
                  <tbody v-if="!isEditing">
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.businessType}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.businessName}}</td>



                    <!-- <td v-if="show.corpDetails.corpInfo != null">{{ formatDate(show.corpDetails.corpInfo.dateEstablish) }}</td> -->
                  </tbody>
                  <tbody v-if="isEditing">
                    <td><input class="form-control" v-model="show.corpDetails.corpInfo.businessType"/></td>
                    <td><input class="form-control" v-model="show.corpDetails.corpInfo.businessName"/></td>
                    
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Registered Business Number:
                      </th>
                      <th>
                       Government Issuing Agency:
                      </th>
                      <th>
                       Country of Issuance:
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isEditing">
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.businessNumber}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.issuingAgency}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.issuanceCountry}}</td>
                  </tbody>
                  <tbody v-if="isEditing">
                    <td><input class="form-control" v-model="show.corpDetails.corpInfo.businessNumber"/></td>
                    <td><input class="form-control" v-model="show.corpDetails.corpInfo.issuingAgency"/></td>
                    <td> <div class=" mobile-container">
                        <div class="input-group mb-3">
                        
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="show.corpDetails.corpInfo.issuanceCountry" v-on:click="toggleCountryListForNationality" >
                        </div>
                        <div class="country-list" v-if="showCountryForNationality">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForNationality(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></td>
                  </tbody>
                </table>

                 <table >
                  <thead>
                    <tr>
                      <th>
                       Office Address 1:
                      </th>
                      <th>
                       City:
                      </th>
                      <th>
                       Province:
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isEditing">
                    <td v-if="show.corpDetails.corpOfficeAddress != null">{{show.corpDetails.corpOfficeAddress.numberStreet}}</td>
                    
                   <td v-if="show.corpDetails.corpOfficeAddress != null">{{show.corpDetails.corpOfficeAddress.cityTown}}</td>
                   
                    <td v-if="show.corpDetails.corpOfficeAddress != null">{{show.corpDetails.corpOfficeAddress.provState}}</td>
                   
                  </tbody>
                  <tbody v-if="isEditing">
                    <td><input class="form-control" v-model="corpDetails.corpInfo.businessAddress.numberStreet"/></td>
                    <td><input class="form-control" v-model="corpDetails.corpInfo.businessAddress.cityTown"/></td>
                   <td><input class="form-control" v-model="corpDetails.corpInfo.businessAddress.provState"/></td>
                   </tbody>
                 </table>
                 <table class="table">
                  <thead>
                    <tr>
                      <th>
                       Country:
                      </th>
                      <th>
                      Postal Code:
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="!isEditing">
                    <td v-if="show.corpDetails.corpOfficeAddress != null">{{show.corpDetails.corpOfficeAddress.country}}</td>
                    <td v-if="show.corpDetails.corpOfficeAddress != null">{{show.corpDetails.corpOfficeAddress.postalCode}}</td>
                   </tbody>
                   <tbody v-if="isEditing">
                   
                    <td> <div class=" mobile-container">
                        <div class="input-group mb-3">
                        
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="corpDetails.corpInfo.businessAddress.country" v-on:click="toggleCountryListForAddress" >
                        </div>
                        <div class="country-list" v-if="showCountryForAddress">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForAddress(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td><input class="form-control" v-model="corpDetails.corpInfo.businessAddress.postalCode"/></td>
                   </tbody>
                </table>
                </form>
                <hr>
                <br>
                
                <form> 
                  <button class="btn btn-secondary" @click.prevent="editBusinessInfo()" v-if="!isEditingExt" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to edit or update the company information">Edit
                </button>
                <div class="text-center" v-if="isEditingExt"> 
                    <span >
                      <button class="btn btn-secondary" type="submit" @click.prevent="updateExtCorpProfile()" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to save the changes made.">Save</button>
                    </span>
                    <span ><button class="btn btn-secondary" v-on:click="cancelEditExt" :disabled="!init.FXCUSTEDIT">Cancel</button></span>
                    
                  </div>
                  <table class="table" >
                  <thead>
                    <tr>
                       <th>
                        Date of Incorporation
                      </th>
                      <th>
                       Trading Name:
                      </th>
                    
                    </tr>
                  </thead>
                  <tbody  v-if="!isEditingExt">
                    <td v-if="show.corpDetails.corpInfo != null">{{formatDate(show.corpDetails.corpInfo.dateEstablish)}}</td>
                    <td v-if="show.corpDetails.corpInfo != null">{{show.corpDetails.corpInfo.tradingName}}</td>
                   
                  </tbody>
                  <tbody v-if="isEditingExt">
                    <td> <table> 
                      <th>Day:</th>
                      <td>
                        <select v-model="dateEstablish.day" @change="updateDate" class="form-control">
                        <option value="">DD</option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    </td>
                    <th>Month:</th>
                    <td >
                      <select v-model="dateEstablish.month" @change="updateDate" class="form-control">
                        <option value="">MM</option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    </td>
                    <th>Year:</th>
                    <td >
                      <select v-model="dateEstablish.year" @change="updateDate" class="form-control">
                        <option value="">YYYY</option>
                        <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                      </select>
                    </td>
                    </table>
                    </td>
                    <td><input class="form-control" v-model="show.corpDetails.corpInfo.tradingName"/></td>
                   
                  
                  </tbody>

                </table>
                <label>Trading Address:</label>
                <table v-if="!isEditingExt">
                  
                  <thead>
                    <tr>
                      <th>
                      Full Address:
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody > 
                    <td v-if="show.corpDetails.tradingAddress != null">{{show.corpDetails.tradingAddress.fullAddress}}</td>
                    <td v-else>N/A</td>
                  </tbody >
                
                 
                 </table>

                 <table class="table" v-if="isEditingExt">
                  <thead>
                    <tr>
                      <th>
                      Address 1
                      </th>
                      <th>
                      City/Town:
                      </th>
                      <th>
                       Prov/State:
                      </th>
                      <th>
                       Country:
                      </th>
                      <th>
                      Postal Code:
                      </th>
                    </tr>
                  </thead>
                   <tbody >
                    <td><input class="form-control" v-model="corpDetails.corpInfo.tradingAddress.numberStreet"/></td>
                    <td><input class="form-control" v-model="corpDetails.corpInfo.tradingAddress.cityTown"/></td>
                    <td><input class="form-control" v-model="corpDetails.corpInfo.tradingAddress.provState"/></td>
                    <td> <div class=" mobile-container">
                        <div class="input-group mb-3">
                        
                          <input type="text" class="form-control form-select" id="formGroupExampleInput" v-model="corpDetails.corpInfo.tradingAddress.country" v-on:click="toggleCountryListForTrading" >
                        </div>
                        <div class="country-list" v-if="showCountryForTrading">
                          <div class="row">
                            <div class="col-sm-6" v-for="l in list.mobileCodes" :key="l.id" v-on:click="selectCountryForTrading(l)" >
                              <div>
                                <img class="flag-list-img" :src="require('@/assets/signup_flags/' + l.countryCode + '.png' )">
                              </div>
                              <div class="country-name">
                                <span> {{ l.country }} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td><input class="form-control" v-model="corpDetails.corpInfo.tradingAddress.postalCode"/></td>
                   </tbody>
                </table>
                </form>
              <hr>
              <br>
              <div class=" table-responsive">
                <button class="btn btn-secondary" @click.prevent="addIndividual()" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to add Individual Associate on behalf of the customer">
                  Add Individual Member
                </button>
                
                 <table class="table">
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>
                       Name:
                      </th>
                      <th>
                       Date of Birth:
                      </th>
                       <th>
                       Association Type:
                      </th>
                      <th>
                       Shares Held:
                      </th>
                      <th >
                        CV
                      </th>
                      <th>
                        ID
                      </th>
                      <th>
                        Uploaded Documents
                      </th>
                      
                    </tr>
                  </thead>
                  <tbody v-for="member in show.corpDetails.diretors" :key='member.id'>
                    <td>{{ member.directorId }}</td>
                  <td>{{member.firstName}} {{member.middleName}} {{member.lastName}}</td>
                   <td>{{member.birthDd}}/{{member.birthMm}}/{{member.birthYear}}</td>
                   <td>{{member.assocType}}</td>
                   <td>{{member.sharesHeld}}</td>
                   
                   <td ><button class="btn btn-primary" @click="uploadDoc(member)" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to upload CV on behalf of the customer">Upload CV</button></td>
                   <!-- <td> <button class="btn btn-secondary" @click="uploadDoc(member)" :disabled="!init.FXCUSTEDIT">Upload </button></td> -->
                   
                   
                  <!-- <td><button class="btn btn-secondary" @click="uploadId(member)" :disabled="!init.FXCUSTEDIT">Upload </button></td>
                   <td> -->
                    <td><button class="btn btn-primary" @click="uploadId(member)" :disabled="!init.FXCUSTEDIT" v-b-tooltip.hover title="Click to upload ID on behalf of the customer">Upload ID</button></td>
                    <td>
                    <table>
                      
                      <tbody v-for="item in poid" :key="item.id">
                        <tr  v-if="member.directorId == item.poidDesc">
                          <td>{{ item.poidType }}</td>
                          <td ><button class="btn btn-primary" @click="viewPoidFile(item)" v-b-tooltip.hover title="Click to view, download and action the uploaded document">view</button></td>
                          <td>{{ item.approvalStatus }}</td>
                          <td>{{ item.comments }}</td>
                          <td>{{ item.reviewedBy }}</td>
                         
                        </tr>
                        <ViewUploadPoid v-if="viewFile" @close="viewPoidFile(item)" :data="poidData" :format="item.fileType" ></ViewUploadPoid>
                      </tbody>
                    </table>
                   </td>
                  
                  </tbody>
                 </table>
              </div>
            </div>
          </div>
         </div>
         </div>
         <AddMember v-if="showAddMember" @close="addIndividual()"/>
         
         <UploadDoc v-if="showUploadDoc" @close="uploadDoc()" :member="member"/>
         <UploadId v-if="showUploadId" @close="uploadId()" :member="member"/>
       
	</div>
</template>
        
<script>
  import moment from 'moment';
  import AddMember from '../components/modal/Documents/AddMember.vue'
  import TheTopbar from '../components/TheTopbar.vue'
  import Approve from '../components/modal/Approve.vue'
  import Header from '../components/Header.vue'
  import UploadDoc from '../components/modal/Documents/UploadDoc.vue'
  import UploadId from '../components/modal/Documents/UploadId.vue'
  import ViewUploadPoid from '../components/modal/Documents/ViewUploadPoid.vue'

  export default {
      name: 'CompanyProfile',
      components: {
        TheTopbar,
        Approve,
        AddMember,
        Header,
        UploadDoc,
        UploadId,
        ViewUploadPoid
         
      },
      computed: {
          show() {
              return this.$store.state.show;
          },
          init() {
              return this.$store.state.init;
          },
       },
       data: function() {
      return { 
        showChangePin: false,
        showLogoutModal: false,
        showAddMember: false,
        selected: '',
          state: 1,
          dateEstablish:'',
          dateEstablish:{
            day: null,
            month: null,
            year: null
          },
          days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
          months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
          years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
          isMobile: false,
          birthdate: '',
          formattedBirthdate: '',
          isUnderage: false,
          month:'',
          day:'',
          year:'',
          errorMsg:'',
          showCountryForNationality: false,
          showCountryForAddress: false,
          ncountry:'PH',
          acountry:'PH',
          corpDetails:{
          corpInfo:{
              businessType: "",
              businessName:"",
              businessNumber:"",
              tradingName:"",
              tradingAddress:"",
              issuingCountry:"",
              govtAgency:"",
              businessAddress:{
                numberStreet:'',
                cityTown:'',
                provState:'',
                country:'',
                postalCode:''
              },
              tradingAddress:{
                numberStreet:'',
                cityTown:'',
                provState:'',
                country:'',
                postalCode:''
              }
              
            }
          },
          errors: {
            profile: {
                msg: "",
                show: false
            }
          },
          viewFile: false,
          showCountry: false,
          selected: 'mobile',
          country: 'PH',
          mobileCode: '63',
          hideOrShow: "Show",
          list: {
              mobileCodes: []
          },
          isError: false,
          showUpload: false,
          showExitModal: false,
          isEditingExt: false,
          isEditing: false,
          isSubmitting: false,
          poid:[],
          poidItem:{},
          directorFiles:'',
          isMatched: false,
          showDetails: false,
          poidData:'',
          poidKey:'',
          member:{},
          showUploadDoc:false,
          showUploadId: false,
          imgUrl: '',
          item:{
            fileType:''
          },
          isEditingExt: false,
          showCountryForTrading: false,

      
      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       

      },
      methods:{
        goHome(){
          window.location.href="/#/customer-search";
        },
        goBack(){
          this.$router.go(-1);
        },
        goToActivityLogs(){
          window.location.href="/#/customer-activity/" + this.$route.params.entityId
        },
        goToCompany(){
          window.location.href="/#/customer-company/" + this.$route.params.entityId
          },
          goToApplication(){
          console.log(this.show.profile.signupHost)
          if(this.show.profile.signupHost == 'fintech.ceza.org'){
            window.location.href="/#/customer-application-list/" + this.$route.params.entityId
          }
          if(this.show.profile.signupHost == 'gaming.ceza.org'){
            window.location.href="/#/customer-gaming-list/" + this.$route.params.entityId
          }
         
        },
        goToProfile(){
          window.location.href="/#/customer-profile/" + this.$route.params.entityId
        },
        goToUsers(){
          window.location.href="/#/customer-users/" + this.$route.params.entityId
        },
        goToTransactions(){
          window.location.href="/#/customer-transactions/" + this.$route.params.entityId
        },
        addIndividual(){
          this.showAddMember = !this.showAddMember
        },
        uploadDoc(member){
          this.member = member
          this.showUploadDoc = !this.showUploadDoc
        },
        uploadId(member){
          this.member = member
          this.showUploadId = !this.showUploadId
        },
        updateDate() {
          if (this.dateEstablish.day && this.dateEstablish.month && this.dateEstablish.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.dateEstablish.day.toString().padStart(2, '0')} ${this.dateEstablish.month.toString().padStart(2, '0')} ${this.dateEstablish.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
      
       editBusinessInfo(){
         if(!this.isEditingExt) {
            this.isEditingExt = true;
          } else {
          this.isSubmitting = true;
          }
       },
       viewPoidFile(doc){
          this.poidData = doc
          this.viewFile = !this.viewFile
        },
       toggleCountryList: function(){
              this.showCountry = !this.showCountry;
              
              },
          
        selectCountry: function(c) {
              this.showCountry = false;
              this.country = c.countryCode;
              this.mobileCode = c.mobileCode;
          },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
        toggleCountryListforAccountAddress(){
        this.showCountryForAccountAddress = !this.showCountryForAccountAddress;
        },
      
        selectCountryForAccountAddress: function(c) {
          this.showCountryForAccountAddress = false;
          this.business.account.address.country = c.country
          this.accountcountry = c.countryCode
        },
        toggleCountryListForNationality(){
            this.showCountryForNationality = !this.showCountryForNationality;
          },
          selectCountryForNationality: function(c) {
            this.showCountryForNationality = false;
            this.show.corpDetails.corpInfo.issuanceCountry = c.countryCode
           
          },
          toggleCountryListForTrading(){
            this.showCountryForTrading = !this.showCountryForTrading;
          },
          selectCountryForTrading: function(c) {
            this.showCountryForTrading = false;
            this.corpDetails.corpInfo.tradingAddress.country = c.countryCode
           
          },
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.corpDetails.corpInfo.businessAddress.country = c.countryCode
           
          },
       
         
          formatDate: function(date) {
          return moment(new Date(date)).format('MM/DD/YYYY');
        },
        getPoid() {
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              data,
              url: '/ax/getCustomerPoids',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data)
                 this.poid = response.data

                 let item = [];
                 this.poid.forEach((el, i) => {
                  item.push(el);
                  this.imgUrl = el.poidFile
                  });
                  this.item.fileType = this.imgUrl.split('.').pop();
                 
              }).catch((err) => {
                  
              })
          },
          
          getProfile() {
            
            const params = {
              eid: this.$route.params.entityId
            }
            const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            data,
              url: '/ax/getCustomerProfile',
              }
              this.axios(options)
              .then((response) => {
                console.log(response.data.corpDetails)
                  this.show.profile = response.data;
                  if(response.data.corpDetails !== ''){
                    this.show.corpDetails = response.data.corpDetails
                  }
             
              }).catch((err) => {
                  
              })
          },
          cancelEditProfile(){
              this.isEditingProfile = false;  
              window.location.reload();
          },
          cancelEditExt(){
              this.isEditingExt = false;  
              window.location.reload();
          },
          

          // updateBusinessInfo(){
          //     const params = {
          //         businessType: this.show.corpDetails.corpInfo.businessType,
          //         businessName: this.show.corpDetails.corpInfo.businessName,
          //         businessNumber: this.show.corpDetails.corpInfo.businessNumber,
          //         issuingAgency: this.show.corpDetails.corpInfo.issuingAgency,
          //         issuanceCountry: this.show.corpDetails.corpInfo.issuanceCountry,
          //         "officeAddress.numberStreet": this.corpDetails.corpInfo.businessAddress.numberStreet,
          //         "officeAddress.cityTown": this.corpDetails.corpInfo.businessAddress.cityTown,
          //         "officeAddress.provState": this.corpDetails.corpInfo.businessAddress.provState,
          //         "officeAddress.country": this.corpDetails.corpInfo.businessAddress.country,
          //         "officeAddress.postalCode": this.corpDetails.corpInfo.businessAddress.postalCode,
                    
          //     }

          //     const data = Object.keys(params)
          //             .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          //             .join('&');

          //             const options = {
          //                 method: 'POST',
          //                 headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
          //                 data,
          //                 url: '/ax/profile/corp/step1/update/' + this.$route.params.entityId
          //                 };
          //         this.axios(options)
          //             .then((response) => { 
          //               console.log(response)
                       
                          
          //                 setTimeout(()=>{
          //                    this.updateExtCorpProfile();
          //                   }, 2000)

          //             }).catch((err) => { 
          //                 this.isSubmitting = true;
          //                 this.isEditingProfile = true; 
          //                 if(err.response.data.fieldErrors){
          //                   this.errors.profile.show = true;
          //                   this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
          //                   setTimeout(()=>{
          //                   this.errors.profile.show = false;
          //                   this.errors.profile.msg = "";
          //                 }, 2000)
          //               }

          //         })
              
          
          // },
          updateExtCorpProfile(){
              const params = {
                companyEmailAddress:  this.show.corpDetails.corpInfo.companyEmailAddress,
                businessPhoneNumber:  this.show.corpDetails.corpInfo.businessPhoneNumber,
                companyWebsite: this.show.corpDetails.corpInfo.companyWebsite,
                businessNature: this.show.corpDetails.corpInfo.businessNature,
                dateEstablish: `${this.dateEstablish.day}/${this.dateEstablish.month}/${this.dateEstablish.year}`,
                salesPerMonth:  this.show.corpDetails.corpInfo.salesPerMonth,
                txnPerMonth:  this.show.corpDetails.corpInfo.txnPerMonth,
                grossIncomeAnnual:  this.show.corpDetails.corpInfo.grossIncomeAnnual,
                contactPerson:  this.show.corpDetails.corpInfo.contactPerson,
                contactEmailAddress:  this.show.corpDetails.corpInfo.contactEmailAddress,
                contactPhoneNumber:  this.show.corpDetails.corpInfo.contactPhoneNumber,
                bankAccountNumber:  this.show.corpDetails.corpInfo.bankAccountNumber,
                bankName:  this.show.corpDetails.corpInfo.bankName,
                bankAccountName: this.show.corpDetails.corpInfo.bankAccountName,
                "tradingAddress.numberStreet": this.corpDetails.corpInfo.tradingAddress.numberStreet,
                "tradingAddress.cityTown": this.corpDetails.corpInfo.tradingAddress.cityTown,
                "tradingAddress.provState": this.corpDetails.corpInfo.tradingAddress.provState,
                "tradingAddress.country": this.corpDetails.corpInfo.tradingAddress.country,
                "tradingAddress.postalCode": this.corpDetails.corpInfo.tradingAddress.postalCode,
              }

              const data = Object.keys(params)
                      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                      .join('&');

                      const options = {
                          method: 'POST',
                          headers: { 'content-type': 'application/x-www-form-urlencoded', 'vmiadmin-authz': localStorage.getItem("tokenId")  },
                          data,
                          url:  '/ax/profile/corp/step2/update/' + this.$route.params.entityId
                          };
                  this.axios(options)
                      .then((response) => { 
                         
                          this.isSubmitting = false;
                          this.isEditingExt = false; 
                          this.errors.profile.show = true;
                          this.errors.profile.msg = "Profile updated successfully";
                          setTimeout(()=>{
                            window.location.reload();
                              this.errors.profile.show = false;
                              this.errors.profile.msg = "";
                            }, 2000)

                      }).catch((err) => { 
                          this.isSubmitting = true;
                          this.isEditingExt = true; 
                          if(err.response.data.fieldErrors){
                            this.errors.profile.show = true;
                            this.errors.profile.msg = err.response.data.fieldErrors[0].defaultMessage
                            setTimeout(()=>{
                            this.errors.profile.show = false;
                            this.errors.profile.msg = "";
                          }, 2000)
                        }

                  })
              
          
          },
          
         
          isNumber: function(evt) {
              evt = (evt) ? evt : window.event;
              var charCode = (evt.which) ? evt.which : evt.keyCode;
              if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
              evt.preventDefault();
              } else {
              return true;
              }
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
        this.axios(options)
              .then((response) => {
              
              this.getProfile();
              }).catch((err) => {
                 

                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getMobileCodes();
          this.getPoid();
      }
  }
</script>
        
<style lang=scss scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
 .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  a .link{
    text-decoration: underline !important;
    color: blue !important;
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    width: 100%;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    /* background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important; */
    border-radius: 10px;;
    /* z-index: 2; */
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }

  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>