<template>
  <div class="backdrop">
    <div class="b-modal">
        <div class="modal-header text-right">
              <h5 class="text-white">Add Individual Associates</h5>
              <p @click="closeModal" class="x">x</p>
          </div>
        <div class="modalBody">
             <div v-if="step == '2'" class="mt-5">
                  <p class="text-white">Successfully added.</p>
                  <p>
                  <button class="btn btn-main submit" @click="done">Done</button>
                  </p>
              </div>

              <div v-if="step == '1'">
                <p v-if="errors.profile.show" style="color: rgb(179, 108, 108)">{{ errors.profile.msg }}</p>
                <h5 class="text-dark">Individual Associates Basic Info</h5>
                
                <form >
                  <div class="market-content">
                  <table class="table">
                  <thead>
                    <tr>
                       <th>
                       First Name:
                      </th>
                      <th>
                       Middle Name:
                      </th>
                      <th>
                       Last Name:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td>
                      <input class="form-control" v-model="profile.firstName"/>
                    </td>
                    <td>
                      <input class="form-control" v-model="profile.middleName"/>
                    </td>
                     <td>
                      <input class="form-control" v-model="profile.lastName"/>
                    </td>
                  </tbody>
                </table>
                 <table class="table">
                  <thead>
                    <tr>
                       <th>
                       Day:
                      </th>
                      <th>
                       Month:
                      </th>
                      <th>
                       Year:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td >
                      <select v-model="profile.birthDd" @change="updateDate" class="form-control">
                        <option value="">DD</option>
                        <option v-for="dayOption in days" :key="dayOption" :value="dayOption">{{ dayOption }}</option>
                      </select>
                    </td>
                    <td >
                      <select v-model="profile.birthMm" @change="updateDate" class="form-control">
                        <option value="">MM</option>
                        <option v-for="(monthOption, index) in months" :key="index" :value="index + 1">{{ monthOption }}</option>
                      </select>
                    </td>
                    <td >
                      <select v-model="profile.birthYear" @change="updateDate" class="form-control">
                        <option value="">YYYY</option>
                        <option v-for="yearOption in years" :key="yearOption" :value="yearOption">{{ yearOption }}</option>
                      </select>
                    </td>
                  </tbody>
                </table>
                 <table class="table">
                  <thead>
                    <tr>
                       <th>
                       ID type:
                      </th>
                      <th>
                       ID Number:
                      </th>
                       <th>
                       Contact details:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                   
                    <td>
                       <select v-model="profile.idType" class="form-control">
                        <option value="" disabled>Select</option>
                        <option value="passport">Passport</option>
                        <option value="driversLicense">Drivers License</option>
                        <option value="nationalId">National ID</option>
                        <option value="acr">Alien Certification of Registration</option>
                        <option value="aep">Alien Employment Permit</option>
                        <option value="sss">SSS</option>
                        <option value="umid">UMID</option>
                      </select>
                    </td>
                     <td>
                      <input class="form-control" v-model="profile.idNumber"/>
                    </td>
                     <td>
                      <input class="form-control" v-model="profile.contactDetails"/>
                    </td>
                  </tbody>
                </table>
                <table class="table">
                  <thead>
                    <tr>
                       <th>
                       Association Type:
                      </th>
                      <th>
                       No. of Shares:
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td>
                      <select  v-model="profile.assocType" class="form-control">
                        <option value="" disabled>Select</option>
                        <option value="beneficialOwner">Beneficial Owner</option>
                        <option value="director">Director</option>
                        <option value="shareholder">Shareholder</option>
                        <option value="authorizedSignatory">Authorized Signatory</option>
                      </select>
                    </td>
                     <td>
                      <input class="form-control" v-model="profile.sharesHeld" @keypress="isNumber($event)"/>
                    </td>
                  </tbody>
                  </table>
                  </div>
                  <div>
                  </div>
                   
                   
                    <div class="form-group ">
                      <button class="btn btn-primary" id="loginBtn" @click.prevent="submitProfile()">Submit Individual Associates Basic Info</button>
                    </div>
    
                    
                 
                </form>
                
    
              </div>
              
  
      
           
         
        </div>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'

export default {
    components: {

    },
    data: function() {
        return {
           uploadError:'',
              step:'1',
              month:'',
              day:'',
              year:'',
              days: Array.from({ length: 31 }, (v, i) => (i + 1).toString().padStart(2, '0')),
              months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
              years: Array.from({ length: 100 }, (v, i) => new Date().getFullYear() - i),
              profile:{
                  fullName:"",
                  firstName:'',
                  lastName:'',
                  middleName:'',
                  birthYear:'',
                  birthMm:'',
                  birthDd:'',
                  address:'',
                  idType:'',
                  idNumber:'',
                  assocType:'',
                  sharesHeld:'',
                  globalName:'-',
                  watchList:'-',
                  comment:'-',
                  contactDetails:"",
                  designation:"-",
                  
              },
             
              errors: {
                  profile: {
                      show: false,
                      msg: ""
                  },
              },
           
            
        }
    },
    computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
       
     
      },
    methods: {
       updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
      submitProfile(){
        const params = {
            entityId: this.$route.params.entityId,
              name: this.profile.firstName + this.profile.middleName + this.profile.lastName,
              contactDetails: this.profile.contactDetails,
              designation: this.profile.designation,
              link: 'link',
              firstName:this.profile.firstName,
              lastName:this.profile.lastName,
              middleName:this.profile.middleName,
              birthYear:this.profile.birthYear,
              birthMm:this.profile.birthMm,
              birthDd:this.profile.birthDd,
              address:this.profile.address,
              idType:this.profile.idType,
              idNumber:this.profile.idNumber,
              assocType:this.profile.assocType,
              sharesHeld:this.profile.sharesHeld,
              globalName:this.profile.globalName,
              watchList:this.profile.watchList,
              comment:this.profile.comment,
           
            }
           
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              params,
              url: '/ax/profile/corp/director/add'
              }
              this.axios(options)
              .then((response) => {
               
                this.$emit("close")
                // window.location.reload();
              }).catch((err) => {
                this.errors.profile.show = true
                this.errors.profile.msg = err.response.data.msgText
               
                setTimeout(()=>{
                  this.errors.profile.show = false
                  this.errors.profile.msg = ''
              }, 3000)
                  
              })
      },
    
      cancel(){
        this.state='init'
      },
        closeModal(){
        this.$emit("close")
        // window.location.reload();
        },
        isNumber: function(evt) {
		        evt = (evt) ? evt : window.event;
		        var charCode = (evt.which) ? evt.which : evt.keyCode;
		        if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
		          evt.preventDefault();
		        } else {
		          return true;
		        }
		      },

       
      
    },
  
    mounted(){
        
        
    }
}
</script>

<style scoped lang=scss>
.backdrop {
    top: 0;
    right:0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 98;
    background: rgba(0, 0, 0, 0.6);

}
.b-modal {
    width: 700px;
    height: auto;
    margin: 20px auto;
    background: white;
    border-radius: 20px;
    border-color:white;
    border-width:2px;
    z-index: 99;
    max-height: calc(100vh - 110px);
      overflow-y: auto;

    color:black;
}

.modal-header {
    background-color: white;
    margin-right:0px;
    font-size:25px;
    display:flex;
    justify-content:space-between;
}

.modal-title{
    margin: 0 auto;
    color:white;
}
.market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
.modalBody {
    background-color:white;
    width:100%;
    padding:50px;
    border-radius: 10px;;
}

.buttonWrapper {
    width:100%;
}

.x {
    cursor:pointer;
}
.form-control{
  border-radius: 10px;;
}
.userPic{
    width: 10rem;
}
.image{
  width: 100%;
}
.img img{
    width: 100%;
    border-radius: 10px;;
     box-shadow: 0 2px 2px rgba(50,50,50,.50);
     border:7px solid #fff;
     border-radius: 10px;;
     background-color: white;
      background-size:cover;
      object-fit: contain;
  }

  .info{
    width: 100%;
    margin-left: 5rem;
    padding-left:1rem;
      .info .name{
      padding: 0.5rem 0 2rem 0;
        .info .name h3{
        font-size: 1rem;
        line-height:110%;
        font-weight: 400;
          .info .name p{
          font-size:0.9rem;
          color: black;
          }
        }
      }
    .info .desc .soc-med{
      display: flex !important;
      flex-direction: row;
      justify-content: space-evenly;
      font-size: 15px !important;
    }
  }
.profile-card {
  height: auto !important;
  width: 100%;
  margin: auto 10px;
  background: Orgba(255,255,255,0.2);
  box-shadow: 0px 8px 60px -10px rgba(13,28,39,0.6);
  border: 4px white solid;
  padding: 5px;
  border-radius: 12px;
  position: relative;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
    &:hover{
    animation-name: bounce;
    animation-timing-function: ease;

    }

}
 

.iconImg {
    width:50%;
}
 .form-group{
        width: 100%;
    }

    .btn-main{
        margin: 30px auto;
        border: 1px solid #481a69;
        width: 100%;
    }
    .btn-main:hover{
        background-color:#481a69;
        color: white;
    }


    @media screen and (max-width: 1100px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
     .heading {
         font-size:25px;
      }

      .subheading {
        font-size:12px;
      }
          
    }
   @media screen and (max-width: 800px) {
     .body {
        height:auto;
        margin: 0 auto;
      }
      .heading {
         font-size:25px;
      }

    .subheading {
      font-size:12px;
    }
    .card {
      width:15rem;
      height:21rem;
      font-size:10px;
    }
        
  }

    @media screen and (max-width: 600px) {
          .firstThree {
             margin: 0 auto;
             display: flex;
             justify-content: center;
        }
         .card {
            width:12rem;
            height:16rem;
            padding-bottom: 10px;
          }
          .card h6{
            font-size: 10px !important;
          }
        
    }

    @media screen and (max-width: 420px) {

       .b-modal {
            width: 400px;
            height: auto;
            margin-top: 50px;
            background: white;
            border-radius: 20px;
            border-color:white;
            border-width:2px;
            z-index: 1;
        }

        .modalBody {
            background-color:white;
            width:100%;
            padding: 20px 0px;
            border-radius: 10px;;
        }
        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:60px;
        }

        .textField {
            width:100px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
        }

        .checkboxGroup {
            width:300px;
            margin:0;
        }

        .sendCode {
            font-size:12px;
            margin-top: 5px;
            text-align:right;
            width:250px;
            font-weight:bold;
            cursor:pointer;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:40px;
            height:80px;
            padding:0;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            font-size:30px;
            margin:2px;
            display: flex !important;
        }

        #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
            width:20px;
            padding:5px;
            border:none;
            color:#191919;
            background-color:lightgray;
            border-radius:3px;
            font-family: 'PoppinsRegular', sans-serif;
            margin:1px;
        }

        .otpLabel {
            font-size:15px;
            width:300px;
            padding:10px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:20px;
        }

        .buttonWrapper {
            display: block;
            justify-content: center;
            width:100%;
        }

    }

    @media screen and (max-width: 380px) {
        .b-modal {
                width: 315px;
        }

        #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
            width:33px;
        }

        #areaCode h3{
            font-size:1px !important;
        }

        .mobileWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .formWrapper {
            display: flex;
            justify-content: flex-start;
            width:100%;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
        }

        .buttonWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .checkWrapper {
            display: block;
            justify-content: flex-start;
            width:100%;
            margin-left:0px;
        }

        .msgCon {
            width:300px;
        }

        .submit {
            background-color:rgb(255, 102, 0);
            color:white;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }

        .cancel {
            color:#191919;
            background-color:lightgray;
            font-family:'PoppinsMedium', sans-serif;
            width:250px;
            margin-right:10px;
            margin-left:20px;
        }
        
    }
    .loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>